import useModalStore from "stores/modal-store";
import dynamic from "next/dynamic";

export enum ModalKey {
  ProposalAccept,
  ProposalDecline,
  ProposalReject,
  SettlementRequest,
  SettlementRequesting,
  DepositRequest, // 지급 요청
  TaxTypeNotice, // 소득세 유형 안내
  DepositScenario, // 지급비용 시나리오 안내
  InterviewDrop, // 만나보고 싶은 멤버가 없으신가요?
  InterviewSelect, // 인터뷰 멤버 선택
  PasswordChanging, // 인터뷰 멤버 선택
  ProjectTerminating, // 협업 종료
  NDA, // 비밀유지 조항 약관
}

const Modal = () => {
  const { currentKey } = useModalStore();

  if (currentKey === null) {
    return <></>;
  }

  switch (currentKey) {
    case ModalKey.ProposalAccept:
      const ProposalAcceptModal = dynamic(() => import("components/modals/ProposalAcceptModal"));
      return (
        <ProposalAcceptModal
          open
        />
      );
    case ModalKey.ProposalDecline:
      const ProposalDeclineModal = dynamic(() => import("components/modals/ProposalDeclineModal"));
      return (
        <ProposalDeclineModal
          open
        />
      );
    case ModalKey.ProposalReject:
      const ProposalRejectModal = dynamic(() => import("components/modals/ProposalRejectModal"));
      return (
        <ProposalRejectModal
          open
        />
      );
    case ModalKey.SettlementRequest:
      const SettlementRequestModal = dynamic(() => import("components/modals/SettlementRequestModal"));
      return (
        <SettlementRequestModal
          open
        />
      );
    case ModalKey.SettlementRequesting:
      const SettlementRequestingModal = dynamic(() => import("components/modals/SettlementRequestingModal"));
      return (
        <SettlementRequestingModal
          open
        />
      );
    case ModalKey.DepositRequest:
      const DepositRequestModal = dynamic(() => import("components/modals/DepositRequestModal"));
      return (
        <DepositRequestModal
          open
        />
      );
    case ModalKey.TaxTypeNotice:
      const TaxTypeNoticeModal = dynamic(() => import("components/modals/TaxTypeNoticeModal"));
      return (
        <TaxTypeNoticeModal
          open
        />
      );
    case ModalKey.DepositScenario:
      const DepositScenarioModal = dynamic(() => import("components/modals/DepositScenarioModal"));
      return (
        <DepositScenarioModal
          open
        />
      );
    case ModalKey.InterviewDrop:
      const InterviewDropModal = dynamic(() => import("components/modals/InterviewDropModal"));
      return (
        <InterviewDropModal
          open
        />
      );
    case ModalKey.InterviewSelect:
      const InterviewSelectModal = dynamic(() => import("components/modals/InterviewSelectModal"));
      return (
        <InterviewSelectModal
          open
        />
      );
    case ModalKey.PasswordChanging:
      const PasswordChangingModal = dynamic(() => import("components/modals/PasswordChangingModal"));
      return (
        <PasswordChangingModal
          open
        />
      );
    case ModalKey.ProjectTerminating:
      const ProjectTerminatingModal = dynamic(() => import("components/modals/ProjectTerminatingModal"));
      return (
        <ProjectTerminatingModal
          open
        />
      );
    case ModalKey.NDA:
      const NDAModal = dynamic(() => import("components/modals/NDAModal"));
      return (
        <NDAModal
          open
        />
      );
  }
}

export default Modal;
