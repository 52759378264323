import Member from 'networks/models/member';
import MemberAccount from 'networks/models/member-account';
import RegularSurvey from 'networks/models/regular-survey';
import create from 'zustand';

interface UserStore {
  me: Member;
  account: MemberAccount;
  regularSurveyList: RegularSurvey[];
  setMe: (data: Member) => void;
  setAccount: (data: MemberAccount) => void;
  setRegularSurveyList: (data: RegularSurvey[]) => void;
}

// set method로 상태 변경 가능
const useUserStore = create<UserStore>(set => (
  {
    me: {} as Member,
    account: {} as MemberAccount,
    regularSurveyList: [] as RegularSurvey[],
    setMe: (data) => set((state) => {
      return {
        ...state,
        me: data,
      };
    }),
    setAccount: (data) => set((state) => {
      return {
        ...state,
        account: data,
      };
    }),
    setRegularSurveyList: (data) => set((state) => {
      return {
        ...state,
        regularSurveyList: data,
      }
    })
  }
));

export default useUserStore;
