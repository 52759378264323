export const apiUrl = process.env.NEXT_PUBLIC_API_URL || 'https://api-dev.cpms.seeso.kr';

export const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:3000';

export const legacyUrl = process.env.NEXT_PUBLIC_LEGACY_URL || 'http://localhost:3000';

export const gtagId = 'GTM-MR9V43W';

export const sentryDSN = 'https://0dec542a87c049deb586a243ae7643af@o80716.ingest.sentry.io/4504643468197888';

export const channelTalkPluginKey = '4338c678-f12e-439d-b578-7a05b36fa249';

const now = new Date().getUTCFullYear();
export const yearList = Array(now - (now - 20)).fill('').map((v, idx) => now - idx);

export const languages = {
  '한국어': '없음 - 한국어만 가능',
  '영어': '영어',
  '중국어': '중국어',
  '일본어': '일본어',
  '스페인어': '스페인어',
  '기타': '기타',
};
