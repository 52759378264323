import { Dialog, Transition } from '@headlessui/react';
import {
  ArrowLeftOnRectangleIcon, Bars3Icon,
  BellIcon, BuildingOfficeIcon, ChatBubbleLeftIcon,
  ComputerDesktopIcon,
  CurrencyDollarIcon, InboxIcon,
  UserGroupIcon,
  UserIcon, XMarkIcon
} from '@heroicons/react/24/outline';
import Link from 'next/link';
import { Router, useRouter } from 'next/router';
import { Fragment, ReactNode, useEffect, useState } from 'react';
import useUserStore from 'stores/user-store';
import ChannelService from 'utils/channel-service';
import { channelTalkPluginKey, legacyUrl } from 'utils/constant';
import { classNames } from 'utils/style-helper';
import Avatar from '../atomics/Avatar';
import Logo from '../atomics/Logo';

type Props = {
  children?: ReactNode;
  // title?: string
}

const memberNavigation = [
  { name: '내 정보', href: '/me', icon: UserIcon, current: false },
  // { name: '세컨팀 클럽', href: '/clubs', icon: UserGroupIcon, current: false },
  // { name: '기업 인재풀', href: '/careers', icon: BuildingOfficeIcon, current: false },

  { name: '받은 제안', href: '/proposals', icon: InboxIcon, current: false },
  { name: 'N잡 현황', href: '/works', icon: ComputerDesktopIcon, current: false },
  { name: '정산 내역', href: '/calc', icon: CurrencyDollarIcon, current: false },

  // { name: '대시보드', href: '#', icon: HomeIcon, current: true },
  // { name: '세컨팀 설문', href: '#', icon: ClipboardDocumentCheckIcon, current: false },
  // { name: '세컨팀 피드', href: '#', icon: GlobeAltIcon, current: false },

  { name: '메세지', href: `${legacyUrl}/messages`, icon: ChatBubbleLeftIcon, current: false },
  { name: '알림', href: '/notification', icon: BellIcon, current: false },
  { name: '로그아웃', href: '/me/logout', icon: ArrowLeftOnRectangleIcon, current: false },
];

const SideBar = ({ children }: Props) => {
  const router = useRouter();
  const pathname = router.pathname.replace(/\/member|\/partner/gi, '');
  const { me } = useUserStore();
  const navigation = memberNavigation; // todo partner/member 분기하기
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    // FIXME: channelTalk 중복 호출 오류 수정 필요
    const channelTalk = new ChannelService();
    channelTalk.boot({
      pluginKey: channelTalkPluginKey,
    });

    const start = () => {
      setSidebarOpen(false);
    };

    const end = () => {
      setSidebarOpen(false);
    };

    Router.events.on("routeChangeStart", start);
    Router.events.on("routeChangeComplete", end);
    Router.events.on("routeChangeError", end);

    return () => {
      Router.events.off("routeChangeStart", start);
      Router.events.off("routeChangeComplete", end);
      Router.events.off("routeChangeError", end);
    };
  }, []);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <Logo
                        width={119}
                        height={32}
                      />
                    </div>
                    <nav className="mt-5 space-y-1 px-2">
                      {
                        navigation.map((item) => {
                          const isCurrent = pathname.startsWith(item.href);
                          return (
                            <Link
                              key={item.name}
                              href={item.href}
                              legacyBehavior
                            >
                              <a
                                className={classNames(
                                  isCurrent
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                  'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    isCurrent ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                    'mr-4 flex-shrink-0 h-6 w-6'
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                            </Link>
                          );
                        })
                      }
                    </nav>
                  </div>
                  <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                    <Link href={`${legacyUrl}/member/profile`} target="_blank" className="group block flex-shrink-0">
                      <div className="flex items-center">
                        <div>
                          <Avatar
                            src={me?.avatar}
                            size={10}
                          />
                        </div>
                        <div className="ml-3">
                          <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                            {me?.nickname}
                          </p>
                          <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                            내 프로필 보기
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
            <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
              <div className="flex flex-shrink-0 items-center px-4">
                <Logo
                  width={119}
                  height={32}
                />
              </div>
              <nav className="mt-5 flex-1 space-y-1 bg-white px-2">
                {
                  navigation.map((item) => {
                    const isCurrent = pathname.startsWith(item.href);
                    return (
                      <Link
                        key={item.name}
                        href={item.href}
                        legacyBehavior
                      >
                        <a
                          className={classNames(
                            isCurrent ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              isCurrent ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                              'mr-3 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </Link>
                    );
                  })
                }
              </nav>
            </div>
            <div className="flex flex-shrink-0 border-t border-gray-200 p-4 cursor-pointer">
              <Link href={`${legacyUrl}/member/profile`} target="_blank" className="group block w-full flex-shrink-0">
                <div className="flex items-center">
                  <div>
                    <Avatar
                      src={me?.avatar}
                      size={9}
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                      {me?.nickname}
                    </p>
                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                      내 프로필 보기
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          {
            children
          }

        </div>
      </div>
    </>
  )
}

export default SideBar;
