import Member from "networks/models/member";
import MemberAccount from "networks/models/member-account";
import RegularSurvey from "networks/models/regular-survey";
import AuthHelper from "utils/auth-helper";
import Service from "./service";

class _UserService extends Service {
  async getProfile(): Promise<Member> {
    const uuid = AuthHelper.getUUID(this.ctx);
    const { data } = await this.api.get(`/members/${uuid}/profile`);
    return data;
  }

  async getAccount(): Promise<MemberAccount> {
    const uuid = AuthHelper.getUUID(this.ctx);
    const { data } = await this.api.get(`/members/${uuid}`);
    return data;
  }

  async getRegularSurveyList(): Promise<RegularSurvey[]> {
    const { data } = await this.api.get('/regular-surveys');
    return data;
  }

  async putAccount(form: any): Promise<void> {
    const uuid = AuthHelper.getUUID(this.ctx);
    await this.api.put(`/members/${uuid}`, form);
  }

  async putProfile(form: any): Promise<void> {
    const uuid = AuthHelper.getUUID(this.ctx);
    await this.api.put(`/members/${uuid}/profile`, form);
  }

  async putAvatar(file: File): Promise<void> {
    const formData = new FormData();
    formData.append('avatar', file);

    const uuid = AuthHelper.getUUID(this.ctx);
    await this.api.put(`/members/${uuid}/profile`, formData);
  }

  async postRegularSurveySubscription(id: number) {
    await this.api.post(`/regular-surveys/${id}/subscription`);
  }

  async deleteRegularSurveySubscription(id: number) {
    await this.api.delete(`/regular-surveys/${id}/subscription`);
  }
}

// todo Member 서비스 / Partner 서비스 분기하기
const UserService = new _UserService();
export default UserService;
